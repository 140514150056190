<template>

	<div style="display:flex;gap:50px;width: 800px;">
		<div style="flex: 1; border-right:1px solid #000;">
			<div style="width: 500px;margin: 20px 0;">
				<h3>裁剪使用例子1 传入url裁剪</h3>

					原始图片:<input v-model="url" style="width: 100%">
					<img :src="url" style="width: 100%">
				<el-button @click="test1">裁剪</el-button>
			</div>

			<hr>

			<div style="width: 500px;margin: 20px 0;">
				<h3>裁剪使用例子2 传入本地图片裁剪</h3>

				<el-upload
					class="upload-demo"
					drag
					action="https://jsonplaceholder.typicode.com/posts/"
					multiple
					:on-change="test2"
					:auto-upload="false">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>

			</div>
		</div>

		<div style="flex: 1;">
			裁剪后:<input v-model="url2" style="width: 100%">
			<img :src="url2" style="width: 100%">
		</div>
	</div>

</template>

<script>

    export default {
        name: "test1",
        components:{

        },
        computed:{
            user() {
                return this.$store.state.user;
            },
        },
        data(){
            return{
				url:"https://oss.baigongbao.com/2023/08/08/eTmaZS3B7C.jpg", //url 地址, base64, blob 都行
				url2:""
            }
        },
        mounted() {

        },
        methods:{

			test1(){
				var that = this;
				this.crop(222,100,this.url,function (url) {
					that.url2 = url;
				})
			},
			test2(file) {
				var that = this;
				const blob = URL.createObjectURL(file.raw);
				this.crop(222,100,blob,function (url) {
					that.url2 = url;
				})
			}
        }
    }
</script>

<style scoped>
</style>
